
import { defineComponent } from "vue";
import PolicyTableDropdownOptions from "@/components/individual/PolicyTableDropdownOptions.vue";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import variables from "@/router/api";
import $ from "jquery";

export default defineComponent({
  name: "policies-table",
  components: { PolicyTableDropdownOptions },
  props: {
    widgetClasses: String,
    policySummary: Object,
    policies: Array
  },
  created() {
    this.displayDatatable();
  },
  methods: {
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    displayDatatable() {
      setTimeout(() => {
        //Add jQuery datatable
        $("#basicExample").DataTable({
          iDisplayLength: 10,
          language: {
            searchPlaceholder: "Search...",
            sSearch: "",
            lengthMenu: "_MENU_ items/page",
            // "lengthMenu": "Display _MENU_ records per page",
            zeroRecords: "No matching records found",
            // "info": "Showing page _PAGE_ of _PAGES_",
            infoEmpty: "No records available",
            infoFiltered: "(filtered from _MAX_ total records)"
          },
          processing: true
        });
      }, 3000);
    }
  }
});
